import React, { useEffect, useState, useMemo, useCallback, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import styles from './mdm-alerts.module.scss';
import classNames from 'classnames';
import { writeFile } from 'xlsx';
import * as XLSX from 'xlsx';
import {
    ArrowDownTrayIcon,
    ChevronDownIcon,
    ChevronUpDownIcon,
    ChevronUpIcon,
    GlobeAmericasIcon,
    XCircleIcon,
    PlusIcon,
} from '@heroicons/react/24/outline';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    flexRender,
    ColumnResizeMode,
    ColumnResizeDirection,
    ColumnFiltersState,
    ColumnDef,
    getFilteredRowModel,
    FilterFn,
    SortingState,
    SortingFn,
    getSortedRowModel,
} from '@tanstack/react-table';
import { columnsAlerts } from './columns';
import {
    getRecipents,
    getCustomer,
    addRecipentToCustomer,
    deleteRecipent,
    getRecipentsBySettings,
    getRecipentsByCustomer,
    patchSetting,
    getTypeAlert,
    postSetting,
    verifyDomain,
    addCustomerDomain,
} from '../../services/python-services';
import Notiflix from 'notiflix';
import Multiselect from 'multiselect-react-dropdown';
import { stylesMultiSelectMDM } from '../../utils/constants';
import { MultiSelectDropdown } from '../multi-select-dropdown/multi-select-dropdown';
import { EmailAddButton } from './sub-components/email-add-button';
import { use } from 'echarts';
import { Modal } from './sub-components/modal';

type SoldTo = {
    created_date: string;
    soldto_code: string;
    soldto_name: string;
};

type GLPAlerts = {
    code: string;
    name: string;
    sold_to: SoldTo[];
    created_date: string;
    country: string;
    alert_by_customer_setting: Object[];
};

export const MdmAlert = () => {
    const [selectedOption, setSelectedOption] = useState('Choose');
    const columns = useMemo(() => columnsAlerts, []);
    const [recipientsData, setRecipients] = useState<string>('');
    const [customers, setCustomers] = useState<GLPAlerts[]>([]);
    const [dataFilteredExport, setDataFilteredExport] = useState<GLPAlerts[]>([]);
    const [recipentsByCustomer, setRecipentsByCustomer] = useState([]);
    const [newEmail, setNewEmail] = useState<{ [key: string]: string }>({});
    const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange');
    const [resEffect, setResEffect] = useState('');
    const [loader, setLoader] = useState(false);
    const [rowSelected, setRowSelected] = useState('');
    const [categorySelected, setCategorySelected] = useState<number>();
    const [globalFilter, setGlobalFilter] = useState('');
    const [countrySelect, setCountrySelect] = useState([]);
    const [isReset, setIsReset] = useState(false);
    const [customerQty, setCustomerQty] = useState(0);
    const [columnResizeDirection, setColumnResizeDirection] =
        useState<ColumnResizeDirection>('ltr');
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [alertType, setAlertTypes] = useState([]);
    const [confirmDomain, setConfirmDomain] = useState(false);
    const [currentDomain, setCurrentDomain] = useState('');
    const [costumerDomain, setCostumerDomain] = useState('');
    const [modalActions, setModalActions] = useState<{
        accept: () => void;
        rejected: () => void;
    } | null>(null);

    const fetchData = async () => {
        try {
            const [response, customerResponse, typeAlerts] = await Promise.all([
                getRecipents(),
                getCustomer(),
                getTypeAlert(),
            ]);
            setRecipients(response);
            setCustomers(customerResponse);
            setAlertTypes(
                typeAlerts.map((x: any) => {
                    return x.id;
                })
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoader(false);
            setNewEmail({});
        }
    };

    useEffect(() => {
        fetchData();
    }, [resEffect]);

    useEffect(() => {
        const { getRowModel } = table;

        const { rows } = getRowModel();
        const dataSent = rows.map((x: any) => {
            return x.original;
        });

        console.log("dataSent", dataSent)

        const dataFiltered = dataSent.filter((x: any) => {
            const isNumeric = /^\d+$/.test(x.code);
            return isNumeric && x.code !== '0000' && x.code !== 'ALL' && x.code !== 'TBA';
        });

        setCustomerQty(dataFiltered.length);
        setDataFilteredExport(dataFiltered)
    }, [customers, columnFilters, globalFilter]);

    const [showAllRecipients, setShowAllRecipients] = useState<{ [key: string]: boolean }>({});

    const toggleShowAll = (rowId: string, columnName: string) => {
        setShowAllRecipients((prevState: any) => ({
            ...prevState,
            [rowId]: {
                ...(prevState[rowId] || {}),
                [columnName]: !(prevState[rowId]?.[columnName] || false),
            },
        }));
    };

    const defaultData = useMemo(() => customers, [customers]);

    const customGlobalFilterFn: FilterFn<GLPAlerts> = (row, columnId, filterValue) => {
        return Object.entries(row.original).some(([key, value]) => {
            if (key === 'alert_by_customer_setting') {
                if (typeof value === 'string') {
                    return value.toLowerCase().includes(filterValue.toLowerCase());
                }
                if (Array.isArray(value)) {
                    return value.some((recipient: any) =>
                        recipient.recipent.email.toLowerCase().includes(filterValue.toLowerCase())
                    );
                }
                return false;
            }

            if (key === 'sold_to') {
                if (Array.isArray(value)) {
                    return value.some((item: any) => {
                        if (typeof item === 'string') {
                            return item.toLowerCase().includes(filterValue.toLowerCase());
                        }
                        if (typeof item === 'object' && item !== null) {
                            return Object.values(item).some(
                                (val) =>
                                    typeof val === 'string' &&
                                    val.toLowerCase().includes(filterValue.toLowerCase())
                            );
                        }
                        return false;
                    });
                }
            }

            if (key === 'recipents') {
                console.log(columnId);
                return false;
            }

            if (typeof value === 'string') {
                return value.toLowerCase().includes(filterValue.toLowerCase());
            }
            if (typeof value === 'object' && value !== null) {
                return Object.values(value).some(
                    (val) =>
                        typeof val === 'string' &&
                        val.toLowerCase().includes(filterValue.toLowerCase())
                );
            }

            return false;
        });
    };

    const getCountries = async () => {
        const predefinedOrder = [
            'ALL',
            'PANAMA',
            'GUATEMALA',
            'EL SALVADOR',
            'HONDURAS',
            'COSTA RICA',
            'NICARAGUA',
            'ECUADOR',
            'DOMINICAN REPUBLIC',
            'VENEZUELA',
            'PARAGUAY',
            'URUGUAY',
            'JAMAICA',
            'TRINIDAD AND TOBAGO',
        ];

        const countrySet = new Set<string>();

        customers.forEach((x: any) => {
            const countryName = x.country?.name?.toUpperCase() || 'NO COUNTRY';
            if (countryName !== 'DEFAULT') {
                countrySet.add(countryName);
            }
        });

        const countrySelectData = Array.from(countrySet);

        const sortedCountries = countrySelectData.sort((a, b) => {
            const indexA = predefinedOrder.indexOf(a);
            const indexB = predefinedOrder.indexOf(b);
            if (indexA === -1 && indexB === -1) {
                return a.localeCompare(b);
            }
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
        });
        console.log(sortedCountries);
        // @ts-expect-error
        setCountrySelect(sortedCountries);
    };

    useEffect(() => {
        getCountries();
    }, [customers]);

    useEffect(() => {
        fetchData();
    }, [resEffect]);

    const table = useReactTable<GLPAlerts>({
        data: defaultData,
        columns,
        state: {
            globalFilter,
            columnFilters,
            sorting,
        },
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        globalFilterFn: customGlobalFilterFn,
        columnResizeMode,
        columnResizeDirection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    });

    const handleSelectChange = (event: any) => {
        setSelectedOption(event);
        setColumnFilters(() => {
            if (event.includes('All')) {
                return [];
            } else {
                return [{ id: 'country', value: event }];
            }
        });
    };

    function clickButton() {
        return new Promise((resolve) => {
            const accept = () => {
                resolve(true);
                handleCloseModal();
            };
            const rejected = () => {
                resolve(false);
                handleCloseModal();
            };

            setModalActions({ accept, rejected });
            handleOpenModal();
        });
    }

    const handleOpenModal = () => {
        setConfirmDomain(true);
        const dialog = document.getElementById('add-report');
        //@ts-expect-error
        if (dialog) dialog.showModal();
    };
    const handleCloseModal = () => {
        setConfirmDomain(false);
        const dialog = document.getElementById('add-report');
        //@ts-expect-error
        if (dialog) dialog?.close();
    };

    const addRecipent = async (
        event: any,
        rowId: string,
        customerSelected: any,
        categoryID: number,
        costumerName: string,
        columnName: string
    ) => {
        try {
            event.preventDefault();
            setLoader(true);
            setRowSelected(rowId);
            setCategorySelected(categoryID);
            const rgxEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            const verifyDomainRegex = /@([^>]+)/;
            const emailInput = newEmail[rowId];
            const match = emailInput.match(verifyDomainRegex);

            //@ts-expect-error
            setCurrentDomain(match[0]);
            setCostumerDomain(costumerName);
            const domain = { domain: match ? match[1] : null };
            if (columnName == 'Channel' && domain.domain == 'samsung.com') {
                Notiflix.Notify.failure(
                    "Domain 'samsung.com' is restricted and can only be added to the 'Samsung Recipients' column."
                );
                setLoader(false);
                setNewEmail({});
                return;
            } else if (columnName == 'Samsung' && domain.domain !== 'samsung.com') {
                Notiflix.Notify.failure(
                    "The 'Samsung Recipients' column only accepts email addresses from the 'samsung.com' domain."
                );
                setLoader(false);
                setNewEmail({});
                return;
            }

            if (rgxEmail.test(emailInput)) {
                const customer = customers.find((c: any) => {
                    console.log(c);
                    console.log(customerSelected);
                    return c.id === customerSelected;
                });
                console.log('customer', customer);
                if (customer) {
                    const emailAlreadyExists = customer.alert_by_customer_setting.some(
                        (setting: any) => setting.recipent.email === emailInput
                    );
                    console.log('does it exist?', emailAlreadyExists);
                    if (emailAlreadyExists) {
                        Notiflix.Notify.failure('This email is already added for this customer.');
                        setLoader(false);
                        return;
                    }
                }

                const email = { email: emailInput };
                const verifyDomainResult = await verifyDomain(customerSelected, domain);
                if (verifyDomainResult.exist == false) {
                    const userDecision = await clickButton();
                    if (!userDecision) {
                        setLoader(false);
                        handleCloseModal();
                        setNewEmail({});
                        return;
                    } else {
                        const domainCustomerBody = {
                            customer: customerSelected,
                            domain: domain.domain,
                        };
                        await addCustomerDomain(domainCustomerBody);
                    }
                }
                try {
                    const res = await addRecipentToCustomer(customerSelected, email);
                    if (res.success) {
                        const r = await getRecipentsByCustomer(customerSelected);
                        r.forEach((x: any) => {
                            for (let t = 0; t < alertType.length; t++) {
                                if (x.email === email.email) {
                                    const data: any = {
                                        customer: customerSelected,
                                        type: alertType[t],
                                        recipent: x.id,
                                        category_id: categoryID,
                                    };
                                    postSetting(data);
                                }
                            }
                        });
                        setRecipentsByCustomer(r);
                        setResEffect(r);
                        Notiflix.Notify.success('Email added successfully.');

                        setNewEmail((prevEmails) => ({
                            ...prevEmails,
                            [rowId]: '',
                        }));
                    }
                } catch (error) {
                    console.error('Error adding recipient:', error);
                    setLoader(false);
                }
            } else {
                Notiflix.Notify.failure('Please type a valid email.');
                setLoader(false);
            }
        } catch (error) {
            console.error(error);
            setLoader(false);
        }
    };

    const handleEmailChange = (event: any, rowId: string) => {
        const { value } = event.target;
        setNewEmail((prevEmails) => ({
            ...prevEmails,
            [rowId]: value,
        }));
    };

    const deleteRecipient = async (
        event: any,
        customerSelected: any,
        recipentsId: any,
        rowId: any,
        categoryID: number
    ) => {
        event.preventDefault();
        setLoader(true);
        setRowSelected(rowId);
        setCategorySelected(categoryID);

        try {
            const data: any = {
                customer: customerSelected,
                recipent: recipentsId,
                category_id: categoryID,
            };
            const res = await patchSetting(data);
            setResEffect(res);
            Notiflix.Notify.success('Email deleted succesfuly');
        } catch (error) {
            console.error('Error deleting recipient:', error);
            setLoader(false);
        }
    };

    const handleNewEmail = (event: any, rowId: string) => {
        const value = event.target.value;
        setNewEmail((prev) => ({ ...prev, [rowId]: value }));
    };

    const renderRecipients = (
        recipients: Object[],
        rowId: string,
        columnName: string,
        cellOriginalID: any,
        costumerName: any
    ) => {
        const limit = 2;
        //@ts-expect-error
        const showAll = showAllRecipients[rowId]?.[columnName];
        const categoryID = columnName == 'Samsung' ? 1 : 2;
        const filteredRecipients = recipients.filter(
            (email: any) => email.category.name === columnName
        );
        const uniqueRecipients = new Map();
        filteredRecipients.forEach((email) => {
            //@ts-expect-error
            uniqueRecipients.set(email.recipent.email, {
                //@ts-expect-error
                email: email.recipent.email,
                //@ts-expect-error
                id: email.recipent.id,
                //@ts-expect-error
                customerId: email.customer_id,
                //@ts-expect-error
                typeName: email.category.name,
            });
        });
        return (
            <div className={styles.emailsAddedBox}>
                {uniqueRecipients.size > 0 && (
                    <>
                        {Array.from(uniqueRecipients.values())
                            .slice(0, showAll ? uniqueRecipients.size : limit)
                            .map((recipient, index) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}
                                    key={`${rowId}-${columnName}-${index}`}
                                >
                                    <div className={styles.emailsAdded}>
                                        {recipient.email}
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>
                                                deleteRecipient(
                                                    e,
                                                    recipient.customerId,
                                                    recipient.id,
                                                    rowId,
                                                    categoryID
                                                )
                                            }
                                        >
                                            <XCircleIcon className="h-5 w-5" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {uniqueRecipients.size > limit && showAll && (
                            <div
                                className={styles.seeMoreButton}
                                onClick={() => toggleShowAll(rowId, columnName)}
                            >
                                See less
                            </div>
                        )}
                    </>
                )}
                {uniqueRecipients.size > limit && !showAll && (
                    <div
                        className={styles.seeMoreButton}
                        onClick={() => toggleShowAll(rowId, columnName)}
                    >
                        See {uniqueRecipients.size - limit} more
                    </div>
                )}
                <EmailAddButton
                    onClickButton={(e) =>
                        addRecipent(e, rowId, cellOriginalID, categoryID, costumerName, columnName)
                    }
                    onHandleEmail={(e) => handleEmailChange(e, rowId)}
                    emailValue={newEmail[rowId] || ''}
                />
                {rowId === rowSelected && categorySelected === categoryID && loader && (
                    <div className={styles.loader}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#282828"
                            className={`w-6 h-6 ${styles.spin}`}
                        >
                            <path d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" />
                        </svg>
                    </div>
                )}
            </div>
        );
    };

    const resetFilters = () => {
        setGlobalFilter('');
        setSelectedOption('Choose');
        setColumnFilters([]);
        setIsReset(true);
        setTimeout(() => {
            setIsReset(false);
        }, 0);
    };

    console.log("defaultData", )

    const exportExcel = () => {
        const formatDate = (dateString: any) => {
            if (!dateString) return 'null';

            const date = new Date(dateString);
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();

            return `${month}-${day}-${year}`;
        };

        const data = dataFilteredExport.map((row: any) => ({
            'Ship to code': row.code === null ? 'null' : row.code,
            'Ship to name': row.name === null ? 'null' : row.name,
            'Sold to code':
                row.sold_to === null
                    ? 'null'
                    : row.sold_to.map((x: any) => x.soldto_code).join(', '),
            'Sold to name':
                row.sold_to === null
                    ? 'null'
                    : row.sold_to.map((x: any) => x.soldto_name).join(', '),
            'Creation date': row.created_date === null ? 'null' : formatDate(row.created_date),
            'Creation country': row.country === null ? 'null' : row.country.name,
            'Channel Recipients':
                row.alert_by_customer_setting === null
                    ? 'null'
                    : Array.from(
                          new Set(
                              row.alert_by_customer_setting
                                  .filter((recipient: any) => recipient.recipent.email.includes('@cheil'))
                                  .map((recipient: any) => recipient.recipent.email)
                          )
                      ).join(', '),
            'Samsung Recipients':
                row.alert_by_customer_setting === null
                    ? 'null'
                    : Array.from(
                          new Set(
                              row.alert_by_customer_setting
                                  .filter((recipient: any) => recipient.recipent.email.includes('@samsung'))
                                  .map((recipient: any) => recipient.recipent.email)
                          )
                      ).join(', '),
        }));
        
        
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'GLP Alerts');

        XLSX.writeFile(workbook, 'MDM_Alerts.xlsx');
    };

    useEffect(() => {
        if (confirmDomain) {
            handleOpenModal();
        } else {
            handleCloseModal();
        }
    }, [confirmDomain]);

    console.log("look here", table.getHeaderGroups())

    return (
        <div className={classNames(styles.root)}>
            <div className={styles.topWrapper}>
                <div className={styles.tittleContainer}>GLP Ship-to Recipients Management</div>
            </div>
            <div className={styles.buttonsTop}>
                <div className={styles.buttonsLeft}>
                    <div className={styles.filter}>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="country">Filter by country</label>
                            <MultiSelectDropdown
                                icon={<GlobeAmericasIcon className="w-5 h-5 text-gray-500" />}
                                options={countrySelect}
                                chooseLegend={'Choose country'}
                                onChange={handleSelectChange}
                                reset={isReset}
                            />
                        </div>
                    </div>
                    <div className={styles.filter} onClick={resetFilters}>
                        <div className={styles.firstNameInput}>
                            <div>
                                <div className={styles.customButton}>
                                    <div className={styles.button} id="reset">
                                        Reset
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonsRight}>
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search"
                        style={{
                            height: '34px',
                            borderRadius: '5px',
                            border: '1px solid #C4C4C4',
                            paddingRight: '20px',
                            width: '250px',
                            fontSize: '12px',
                        }}
                    />
                    <div className={styles.filter}>
                        <div className={styles.firstNameInput}>
                            <div>
                                <div className={styles.customButton}>
                                    <div className={styles.button} id="reset" onClick={exportExcel}>
                                        <ArrowDownTrayIcon className="h-4 w-4" /> Export
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    margin: '5px 35px 0px',
                    maxHeight: '78vh',
                    overflowY: 'scroll',
                }}
            >
                <div style={{ direction: table.options.columnResizeDirection }}>
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            {...{
                                                key: header.id,
                                                colSpan: header.colSpan,
                                                style: {
                                                    width: header.getSize(),
                                                    position: 'sticky',
                                                    overflow: 'auto',
                                                    borderRight:
                                                        header.id === 'recipients'
                                                            ? '2px solid #C4C4C4'
                                                            : undefined,
                                                    borderLeft:
                                                        header.id === 'recipients'
                                                            ? '2px solid #C4C4C4'
                                                            : undefined,
                                                    borderTop: ' 1px solid lightgray',
                                                    top: '0',
                                                    height: '30px',
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div
                                                        {...{
                                                            className: header.column.getCanSort() &&
                                                                header.id !== 'Channel' &&
                                                                header.id !== 'Samsung'
                                                                ? 'cursor-pointer select-none'
                                                                : '',
                                                            onClick:
                                                                header.id !== 'Channel' && header.id !== 'Samsung'
                                                                    ? header.column.getToggleSortingHandler()
                                                                    : undefined,
                                                            style: {
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '5px',
                                                            },
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                        </div>

                                                        {(header.id !== 'Channel' && header.id !== 'Samsung') && (
                                                            {
                                                                desc: (
                                                                    <ChevronDownIcon className="w-4 h-4" />
                                                                ),
                                                                asc: (
                                                                    <ChevronUpIcon className="w-4 h-4" />
                                                                ),
                                                            }[header.column.getIsSorted() as string] ?? (
                                                                <ChevronUpDownIcon className="w-6 h-6" />
                                                            )
                                                        )}
                                                    </div>
                                                )}

                                                <div
                                                    {...{
                                                        onDoubleClick: () =>
                                                            header.column.resetSize(),
                                                        onMouseDown: header.getResizeHandler(),
                                                        onTouchStart: header.getResizeHandler(),
                                                        className: `${styles.resizer} ${
                                                            table.options.columnResizeDirection
                                                        } ${
                                                            header.column.getIsResizing()
                                                                ? 'isResizing'
                                                                : ''
                                                        } ${header.column.getCanSort()}`,
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                />
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id} style={{ height: '45px' }}>
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            {...{
                                                key: `${row.id}_${cell.id}`,
                                                style: {
                                                    width:
                                                        cell.column.id === 'addRecipients'
                                                            ? '20px'
                                                            : cell.column.getSize(),
                                                    borderLeft:
                                                        cell.column.id === 'Samsung' ||
                                                        cell.column.id === 'Channel'
                                                            ? '1.5px solid #C4C4C4'
                                                            : undefined,
                                                },
                                                className:
                                                    cell.column.id === 'Samsung' ||
                                                    cell.column.id === 'Channel'
                                                        ? styles.tableCell
                                                        : styles.recipients,
                                            }}
                                        >
                                            {cell.column.id === 'Samsung' ||
                                            cell.column.id === 'Channel'
                                                ? renderRecipients(
                                                      cell.getValue<
                                                          GLPAlerts['alert_by_customer_setting']
                                                      >(),
                                                      row.id,
                                                      cell.column.id,
                                                      //@ts-expect-error
                                                      cell.row.original.id,
                                                      cell.row.original.name
                                                  )
                                                : flexRender(
                                                      cell.column.columnDef.cell,
                                                      cell.getContext()
                                                  )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className={styles.tdFoot}></td>
                                <td colSpan={1000} className={styles.tdFoot}>
                                    Unique Ship to Values: {customerQty}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <Modal
                className="addReport"
                isMultiple={false}
                closeDialog={handleCloseModal}
                name={'add-report'}
                domain={currentDomain}
                costumerDomain={costumerDomain}
                onReject={modalActions?.rejected}
                onAccept={modalActions?.accept}
            />
        </div>
    );
};

function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    style,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
    style?: any;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <input {...props} value={value} onChange={(e) => setValue(e.target.value)} style={style} />
    );
}
